import axios from 'axios';
import {
  SHOW_MANAGEMENT_LOADING,
  GET_MANAGEMENT_DETAILS,
  MANAGEMENT_ERROR,
  GET_MANAGEMENTS,
  SHOW_EDIT_MANAGEMENT_LOADING,
  DELETE_MANAGEMENT,
  CREATE_MANAGEMENT,
  EDIT_MANAGEMENT,
} from './types';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { setNotification } from 'components/shared-components/notification/Notification';

/**
 * maco-magsael-sc-568: Management - Overhaul management module
 * olain-tarcina-sc-622: Management - reset management
 * maco-magsael-sc-654: Management - Docs Bug Fixes
 * maco-magsael-sc-679: Management - add interface to upload logo for company and boats
 */

// Get management details
export const getManagementDetails = (managementID) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_MANAGEMENT_LOADING,
    });

    const res = await axios.get(`/api/managements/${managementID}`);

    dispatch({
      type: GET_MANAGEMENT_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: MANAGEMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get all management companies
export const getManagements = () => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_MANAGEMENT_LOADING,
    });

    const res = await axios.get('/api/managements');

    dispatch({
      type: GET_MANAGEMENTS,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: MANAGEMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Create / Edit Management Companies
export const updateManagement =
  (formData, createNew, managementID, history) => async (dispatch) => {
    dispatch({
      type: SHOW_EDIT_MANAGEMENT_LOADING,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const name = formData.name;
    const address = formData.address;
    const contact = formData.contact;
    const free = formData.free;
    // Modification start - 08.29.2024 - maco-magsael-sc-679
    const images = [];

    formData?.images?.length > 0 &&
      formData?.images?.forEach((image) => {
        image && image.response && image.response.url
          ? images.push({
              name: image.name,
              uid: image.uid,
              type: image.type,
              url: image.response.url,
            })
          : images.push({
              name: image.name,
              uid: image.uid,
              type: image.type,
              url: image.url,
            });
      });
    // Modification end - 08.29.2024 - maco-magsael-sc-679

    const body = JSON.stringify({
      name,
      contact,
      address,
      free,
      createNew,
      managementID,
      // Modification start - 08.29.2024 - maco-magsael-sc-679
      images,
      // Modification end - 08.29.2024 - maco-magsael-sc-679
    });

    try {
      const res = await axios.post('/api/managements', body, config);

      if (createNew) {
        dispatch({
          type: CREATE_MANAGEMENT,
          payload: res.data,
        });

        history.push(`${APP_PREFIX_PATH}/management/${res.data._id}`);
      } else {
        dispatch({
          type: EDIT_MANAGEMENT,
          payload: res.data,
        });
      }
    } catch (err) {
      console.info('err', err);

      dispatch({
        type: MANAGEMENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      setNotification('error', 'Error in form creation');
    }
  };

// modification start - 01.24.2024 - maco-magsael-sc-568
// Add Management Boat
export const addManagementBoat =
  (managementID, managementName, boatID) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      managementID,
      boatID,
    });

    try {
      // Modification start - 10.04.2024 - olain-tarcina-sc-622
      // Modification start - 06.26.2024 - maco-magsael-sc-654
      if (managementName !== 'None' && managementID) {
        // Modification end - 06.26.2024 - maco-magsael-sc-654
        await axios.post('/api/managements/management_boat', body, config);
        // Modification start - 06.26.2024 - maco-magsael-sc-654
        await axios.put(
          `/api/docs/change_management/${managementID}/${boatID}`
        );
        // Modification end - 06.26.2024 - maco-magsael-sc-654
      } else {
        await axios.put(`/api/managements/reset-management/${boatID}`);
      }
      // Modification end - 11.04.2024 - olain-tarcina-sc-622
    } catch (err) {
      console.info('err', err);

      dispatch({
        type: MANAGEMENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      setNotification('error', 'Error in form creation');
    }
  };
// modification end - 01.24.2024 - maco-magsael-sc-568

// Delete Management
export const deleteManagement = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_MANAGEMENT_LOADING,
    });

    await axios.delete(`/api/managements/${id}`);

    dispatch({
      type: DELETE_MANAGEMENT,
      payload: id,
    });
  } catch (err) {
    console.info('err', err);
    dispatch({
      type: MANAGEMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Error deleting');
  }
};
